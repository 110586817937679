import React from 'react'
import {Link} from 'react-router-dom'

export default function header() {
    console.log(window.location.pathname)
    return (
        <>
          <div id="pagewrap">
    <nav class="main-nav">
      <h1><Link to="/" style={{textDecoration: 'none'}}>Kano Paie</Link> </h1>
      <ul>
        <li className={window.location.pathname === '/' ? 'active' : ''}> <Link to="/" style={{textDecoration: 'none'}} > Absences </Link></li>
        <li className={window.location.pathname === '/ticket-restaurant' ? 'active' : ''}><Link to="/ticket-restaurant" style={{textDecoration: 'none'}}>Ticket Restau</Link></li>
      </ul>
    </nav>
    </div>
        </>
    )
}