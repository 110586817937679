import React from 'react';
import './App.scss';
import { ExcelRenderer } from 'react-excel-renderer';
import payfit from './ticket_restaurant_payfit_template.xlsx'
import { Renderer } from "xlsx-renderer";
import { saveAs } from "file-saver";
import { FcOk, FcHighPriority } from "react-icons/fc";
import { FiDownload }from "react-icons/fi";
import Header from './Header'


export default class LunchTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // THIS IS THE ITEMS THAT IS PROCESSED WELL 
      items: {
        employees: [],
      },
  
      // INFO THAT WE GET FROM THE PAYFIT FILES TO GET ID WITH MATRICULE AND ANALYTICS
      employesWithPayfitIds: [],
      month: ["janvier","février","mars","avril","mai","juin","juillet","aout","septembre","octobre","novembre","décembre"],
      d: new Date(),
      // THIS IS FILES THAT WILL BE AVAILABLE.
      availableFiles: [],
  
      // SOME UTILS STATE
  
      // STATE TO KNOW IF THE NEEDED FILES ARE UPLOADED OR NOT.
  
      isUploaded: [false, false],
  
      // STATE TO HANDLE ERROR IF FILES UPLOADED ARE NOT FORMATED WELL.
  
      waitedPropertyForPayfit: ['Identifiant (ne pas modifier)', 'Analytiques', 'Matricule'],
  
      waitedPropertyForVsa: [],
  
      errorUploadingPayfitFile:{
        fileName: '',
        isAnError: false,
      },
      errorUploadingVsaFile: {
        fileName: '',
        isAnError: false,
      }
    }  
  }
  checker= (arr, target) => target.every(v => arr.includes(v))
 // IMPORT AND EXPORT FUNCTIONS 
  fileHandler = (event) => {
    this.setState({
      items:{
        employees : []
      },
      itemsToComplete: {
        employees: []
      }
    })
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if(err){
        console.log(err);            
      }
      else {
        this.setState({ 
          errorUploadingVsaFile: {
            ...this.state.errorUploadingVsaFile,
            fileName: fileObj.name
          }
        })
        if(!this.checker(resp.rows[0], this.state.waitedPropertyForVsa)) {
          this.setState({
            isUploaded : [
              false,
              ...this.state.isUploaded.slice(1, this.state.isUploaded.length),
            ],
            errorUploadingVsaFile: {
              ...this.state.errorUploadingVsaFile,
              isAnError: true,
              errorDesc: 'Le fichier importé ne correspond pas aux attentes. Merci d\'insérer un import VSA.xlsx'
            }
          })
          return
        }
        const newRows = resp.rows.filter((e) => e.length > 0)
        // basically, we need to use the first row, that is actually the columns to associate them with the rows
        const rows = newRows.slice(1, newRows.length).map((row) => {
        const obj3 = {};
        // here, we create an object for each rows and associate column to value.
        row.forEach((element, index) => {
          if(obj3[[resp.rows[0][index].split(' ').join('').replace('\'', '')]]) {
            obj3[['fin' + resp.rows[0][index].split(' ').join('').replace('\'', '')]] = element;
          } else {
            obj3[[resp.rows[0][index].split(' ').join('').replace('\'', '')]] = element;
          }
        });
        return obj3
      })
      const rowsManipulated = rows.map((employe) => {
        const employeWithPayfitData = this.state.employesWithPayfitIds.find((e) => e.matricule === employe.Matricule)
        return {
          ...employe,
          analytique: employeWithPayfitData?.analytique ?? undefined,
          payfitId: employeWithPayfitData?.identifiantPayfit ?? undefined
        }
        })
      // im just creating differents key on the object, so the template will not display if undefined.
      this.setState({
        items: {
            employees : [...rowsManipulated],
        },
        errorUploadingVsaFile: {
          ...this.state.errorUploadingVsaFile,
          isAnError:false,
        },
        isUploaded : [
          true,
          ...this.state.isUploaded.slice(1, this.state.isUploaded.length),
        ],
        availableFiles: [
          this.state.items.employees.length > 0 ? {
            name: `ticket_restaurant_payfit_export_${this.state.month[this.state.d.getMonth()]}.xlsx`,
            description :  this.state.items.employees.filter((e) => e.payfitId).length > 1? 'lignes ont été traitées. Il suffit maintenant d\'importer le fichier sur Payfit' :'ligne a été traitée. Il suffit maintenant d\'importer le fichier sur Payfit' ,
            processedRows : this.state.items.employees.filter((e) => e.payfitId).length
          } : '',
      ]
      }) 
    }
    });    
  }
  filePayfitHandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if(err){
        console.log(err);            
      }
      else {
        this.setState({ 
          errorUploadingPayfitFile: {
            ...this.state.errorUploadingPayfitFile,
            fileName: fileObj.name
          }
        })
        if(!this.checker(resp.rows[0], this.state.waitedPropertyForPayfit)) {
          this.setState({
            isUploaded : [
              ...this.state.isUploaded.slice(0,1),
              false,
            ],
            errorUploadingPayfitFile: {
              ...this.state.errorUploadingPayfitFile,
              isAnError: true,
              errorDesc: 'Le fichier importé ne correspond pas aux attentes. Merci d\'insérer un import Payfit.xlsx contenant les identifiants, les matricules, les analytiques'
            }
          })
          return
        }
        const newRows = resp.rows.filter((e) => e.length > 0)
        const rows = newRows.slice(1, newRows.length).map((row) => {
          const obj3 = {};
          // here, we create an object for each rows and associate column to value.
          row.forEach((element, index) => {
            if(obj3[[resp.rows[0][index].split(' ').join('').replace('\'', '')]]) {
              obj3[['fin' + resp.rows[1][index].split(' ').join('').replace('\'', '')]] = element;
            } else {
              obj3[[resp.rows[0][index].split(' ').join('').replace('\'', '')]] = element;
            }
          });
          return obj3
        })
        this.setState({
          employesWithPayfitIds : rows.map((e) => {
            return {
              identifiantPayfit: e['Identifiant(nepasmodifier)'],
              matricule: e['Matricule'],
              analytique: e['Analytiques'],
              employe: e['Employé']
            }
          }),
        })
        if(this.state.items.employees.length > 0) {
          this.setState({
            items: {
              employees : [...this.state.items.employees.map((e, i) => {
                const employeeee = this.state.employesWithPayfitIds.find((emp) => emp.matricule === e.Matricule.toString())
                if(!employeeee) {
                  this.setState({
                    itemsToComplete: {
                      employees: [...this.state.itemsToComplete.employees, e]
                    }
                  })
                  return e
                }
                else {
                  return {
                    ...e,
                    Matricule: e.Matricule.toString(),
                    payfitId: employeeee.identifiantPayfit,
                    analytique: employeeee.analytique,
                  }
                }

              })]
            },
          })
        }
        this.setState({
          items: {
            employees : [...this.state.items.employees.filter((e) => e.payfitId)]
          }
        })
        this.setState({
          availableFiles: [
            this.state.items.employees.length > 0 ? {
              name: `ticket_restaurant_payfit_export_${this.state.month[this.state.d.getMonth()]}.xlsx`,
              description :  this.state.items.employees.filter((e) => e.payfitId).length > 1? 'lignes ont été traitées. Il suffit maintenant d\'importer le fichier sur Payfit' :'ligne a été traitée. Il suffit maintenant d\'importer le fichier sur Payfit' ,
              processedRows : this.state.items.employees.filter((e) => e.payfitId).length
            } : '',
        ],
        errorUploadingPayfitFile: {
          ...this.state.errorUploadingPayfitFile,
          isAnError:false,
        },
      isUploaded : [
        ...this.state.isUploaded.slice(0,1),
        true,
      ],
        }) 
      }
  })}
  async updateExcelFile(
    ) {
      if(this.state.items.employees.length > 0) {
        fetch(payfit)
        .then((response) => response.arrayBuffer())
        .then((buffer) => new Renderer().renderFromArrayBuffer(buffer, this.state.items))
        .then((report) => report.xlsx.writeBuffer())
        .then((buffer) => {
          saveAs(new Blob([buffer]), `ticket_restaurant_payfit_export_${this.state.month[this.state.d.getMonth()]}.xlsx`)
        })
        .catch((err) => console.log("Error writing excel export", err))
      }
    }
  render() {
    return (
          <div className="App">
            <Header/> 
            <div id="confetti-wrapper">
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
                <div class="confetti"></div>
            </div>
            <h5 style={{textAlign: 'center'}}> La page pour le traitement automatique des <strong> Tickets Restaurants  </strong></h5>
            <div className='flex'>
                <form id="form-file-upload" onSubmit={(e) => e.preventDefault()}>
                    <input type="file"  onChange={this.fileHandler.bind(this)} id="input-file-upload" multiple={true} />
                    <label id="label-file-upload" htmlFor="input-file-upload">
                        { this.state.errorUploadingVsaFile.isAnError ?  <FcHighPriority  className='top_right_absolute'/>  : '' }
                        { this.state.isUploaded[0] ?  <FcOk  className='top_right_absolute'/>  : '' }
                        <div className="flex flex-column">
                            <div> Importer un fichier VSA </div>
                            <div style={{textDecoration: 'underline', fontSize:'0.7em'}}>{this.state.errorUploadingVsaFile.fileName} </div>
                            <span> { this.state.errorUploadingVsaFile.isAnError ?  <div style={{color: "red"}}>{this.state.errorUploadingVsaFile.errorDesc} </div>  : '' }
                            </span>
                        </div> 
                    </label>
                </form>
                <form  id="form-file-upload_payfit" onSubmit={(e) => e.preventDefault()}>
                    <input type="file"  onChange={this.filePayfitHandler.bind(this)} id="input-file-upload_payfit" multiple={true} />
                    <label id='label-file-upload_payfit' htmlFor="input-file-upload_payfit">
                        { this.state.errorUploadingPayfitFile.isAnError ?  <FcHighPriority  className='top_right_absolute'/>  : '' }
                        { this.state.isUploaded[1] ?  <FcOk  className='top_right_absolute'/>  : '' }
                        <div className="flex flex-column">
                        <div> Importer un fichier Payfit </div>
                        <div style={{textDecoration: 'underline', fontSize:'0.7em'}}> {this.state.errorUploadingPayfitFile.fileName}</div>
                        { this.state.errorUploadingPayfitFile.isAnError ?  <div style={{color: "red"}}> {this.state.errorUploadingPayfitFile.errorDesc} </div>  : '' }
                        </div> 
                    </label>
                </form>
              </div>
              <div> 
                {
                !this.state.isUploaded.includes(false)? 
                <div className='text-center' style={{color: '#efa666'}}> 
                <strong> Ces fichiers sont disponibles au téléchargement : </strong> 
                {this.state.availableFiles.map((e) => {
                    if(e === '') return <> </>
                    return <div key={e.name}> {e.name} : {e.processedRows} {e.description} </div> 
                    })} 
                <div id="wrap" onClick={this.updateExcelFile.bind(this)}>
                    <div class="btn-slide2">
                    <span class="circle2" > <FiDownload/> </span>
                    <span class="title2" > Télécharger </span>
                    <span class="title-hover2" > Cliquez ici </span>
                    </div>
                </div>
                </div>
                : ''
                }
                </div>
                <div className='credits'> 
                Developpé par <a href="https://www.linkedin.com/in/davidbasset/"> David Basset </a>
                </div>
            </div>

    );

  }
}

