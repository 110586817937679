import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import LunchTicket from './LunchTicket';
import * as serviceWorker from './serviceWorker';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>
    },
    {
      path: "/ticket-restaurant",
      element: <LunchTicket/>,
    },
  ]);


ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
