import React from 'react';
import './App.scss';
import { ExcelRenderer } from 'react-excel-renderer';
import payfit from './payfit.xlsx'
import vsaProcess from './vsa_template_still_to_process.xlsx'
import { Renderer } from "xlsx-renderer";
import { saveAs } from "file-saver";
import { FcOk, FcHighPriority } from "react-icons/fc";
import { FiDownload }from "react-icons/fi";
import Header from './Header'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // THIS IS THE ITEMS THAT IS PROCESSED WELL 
      items: {
        employees: [],
      },
  
      // THIS IS THE ITEMS THAT NEED MANUAL PROCESSING.
      itemsToComplete: {
        employees: []
      },
  
      // INFO THAT WE GET FROM THE PAYFIT FILES TO GET ID WITH MATRICULE AND ANALYTICS
      employesWithPayfitIds: [],
  
      // THIS IS FILES THAT WILL BE AVAILABLE.
      availableFiles: [],
  
      // SOME UTILS STATE
      month: ["janvier","février","mars","avril","mai","juin","juillet","aout","septembre","octobre","novembre","décembre"],
      d: new Date(),
  
      // STATE TO KNOW IF THE NEEDED FILES ARE UPLOADED OR NOT.
  
      isUploaded: [false, false],
  
      // STATE TO HANDLE ERROR IF FILES UPLOADED ARE NOT FORMATED WELL.
  
      waitedPropertyForPayfit: ['Identifiant (ne pas modifier)', 'Analytiques', 'Matricule'],
  
      waitedPropertyForVsa: ['Collaborateur', 'Matricule', 'Type', 'Premier jour', 'Qd', 'Dernier jour', 'Qd', 'Jours', 'Détail des jours', 'Statut'],
  
      errorUploadingPayfitFile:{
        fileName: '',
        isAnError: false,
      },
      errorUploadingVsaFile: {
        fileName: '',
        isAnError: false,
      }
    }  
  }

  

  // UTILS
  ExcelDateToJSDate(date) {
    if(!date) return 
    const [year, month, day] = date.toISOString().split('T')[0].split('-');
    return [day, month, year].join('/');
  }
  AnalyzeByDayAndReturnCategory (employe, typeOfAbs) {
    const { premierJour:debutDate, dernierJour:finDate, Jours:jours, Qd, finQd } = employe
    let debutChoix
    let finChoix
    if (debutDate === finDate  && jours === 0.5) {
      if(Qd === 'Matin' && finQd === 'Midi') {
        debutChoix = 'Matin'
        finChoix = 'Matin'
      } else if (Qd === 'Midi' && finQd === 'Soir'){
        debutChoix = 'Après-midi'
        finChoix = 'Journée entière'
      }
      }
    else if (debutDate === finDate  && jours === 1 ) {
      debutChoix = 'Journée entière'
      finChoix = 'Journée entière'
      } 
      //Plusieurs jours début midi, fin soir :
    else if(debutDate !== finDate  && jours % 1 > 0 &&  Qd === 'Midi' && finQd === 'Soir') {
      debutChoix = 'Après-midi'
      finChoix = 'Journée entière'
      }
      //Plusieurs jours début matin, fin  midi :
    else if(debutDate !== finDate  && jours % 1 > 0 &&  Qd === 'Matin' && finQd === 'Midi') {
      debutChoix = 'Journée entière'
      finChoix = 'Matin'
      }
      //Plusieurs jours complet : 
    else if(debutDate !== finDate  && jours % 1 === 0) {
      debutChoix = 'Journée entière'
      finChoix = 'Journée entière'
    }
    switch(typeOfAbs) {
      case 'Congé payé' : 
      return {
        choixDebutCp: debutChoix,
        choixFinCp:  finChoix
      }
      case 'RTT': 
      return {
        choixDebutRtt: debutChoix,
        choixFinRtt: finChoix
      }
      case 'Congé sans solde':
        return {
          choixDebutSansSolde: debutChoix,
          choixFinSansSolde: finChoix
        }
      default: 
      return {}
    }
  }
  checker= (arr, target) => target.every(v => arr.includes(v))
  adjustDatesToCurrentMonth = (start, end, employe ) => {
    function isBefore(date1, date2) {
      return date1 < date2;
    }
    function isAfter(date1, date2) {
      return date1 > date2;
    }
    const currentMonth = new Date().getMonth()
    if((start.getMonth() < currentMonth && end.getMonth() < currentMonth && start.getFullYear() === end.getFullYear()) 
    || (start.getMonth() > currentMonth && end.getMonth() > currentMonth && start.getFullYear() === end.getFullYear()))
     {
      return {
        start,
        end,
      }
    }
    return {
      start: isBefore(start, this.getFirstDayOfTheMonth()) ? this.getFirstDayOfTheMonth(): start,
      end : isAfter(end, this.getLastDayOfTheMonth()) ? this.getLastDayOfTheMonth(): end
    }
  }
  getLastDayOfTheMonth = () => {
    var lastDay = new Date(this.state.d.getFullYear(), this.state.d.getMonth()+1, 0);
    lastDay.setHours(10)
    return lastDay
  }
  getFirstDayOfTheMonth = () => {
    var firstDay = new Date(this.state.d.getFullYear(), this.state.d.getMonth(), 1)
    firstDay.setHours(10) 
    return firstDay   // get first day of the month
  }
 // IMPORT AND EXPORT FUNCTIONS 
  fileHandler = (event) => {
    this.setState({
      items:{
        employees : []
      },
      itemsToComplete: {
        employees: []
      }
    })
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if(err){
        console.log(err);            
      }
      else {
        this.setState({ 
          errorUploadingVsaFile: {
            ...this.state.errorUploadingVsaFile,
            fileName: fileObj.name
          }
        })
        if(!this.checker(resp.rows[0], this.state.waitedPropertyForVsa)) {
          this.setState({
            isUploaded : [
              false,
              ...this.state.isUploaded.slice(1, this.state.isUploaded.length),
            ],
            errorUploadingVsaFile: {
              ...this.state.errorUploadingVsaFile,
              isAnError: true,
              errorDesc: 'Le fichier importé ne correspond pas aux attentes. Merci d\'insérer un import VSA.xlsx'
            }
          })
          return
        }
        const newRows = resp.rows.filter((e) => e.length > 0)
        // basically, we need to use the first row, that is actually the columns to associate them with the rows
        const rows = newRows.slice(1, newRows.length).map((row) => {
        const obj3 = {};
        // here, we create an object for each rows and associate column to value.
        row.forEach((element, index) => {
          if(obj3[[resp.rows[0][index].split(' ').join('').replace('\'', '')]]) {
            obj3[['fin' + resp.rows[0][index].split(' ').join('').replace('\'', '')]] = element;
          } else {
            obj3[[resp.rows[0][index].split(' ').join('').replace('\'', '')]] = element;
          }
        });
        return obj3
      })
      const rowsManipulated = rows.map((employe) => {
        const employeWithPayfitData = this.state.employesWithPayfitIds.find((e) => e.matricule === employe.Matricule.toString())
        const firstDay = new Date(Math.round((employe.Premierjour - 25569)*86400*1000));
        const lastDay = new Date(Math.round((employe.Dernierjour - 25569)*86400*1000));
        const {start, end} = this.adjustDatesToCurrentMonth(firstDay, lastDay, employe);
        const currentMonth = new Date().getMonth()
        if((start.getMonth() < currentMonth && end.getMonth() < currentMonth && start.getFullYear() === end.getFullYear()) 
        || (start.getMonth() > currentMonth && end.getMonth() > currentMonth && start.getFullYear() === end.getFullYear())) {
          return {
            ...employe,
            Matricule: employe.Matricule.toString(),
            premierJour: this.ExcelDateToJSDate(firstDay),
            dernierJour: this.ExcelDateToJSDate(lastDay),
            Statut: 'Wrong'
          }
        } else return {
          ...employe,
          Matricule: employe.Matricule.toString(),
          premierJour: this.ExcelDateToJSDate(start),
          dernierJour: this.ExcelDateToJSDate(end),
          analytique: employeWithPayfitData?.analytique ?? undefined,
          payfitId: employeWithPayfitData?.identifiantPayfit ?? undefined
        }
      })
      // this is the part where we are making interpretations
      // The hard part is to dispatch on differents columns on the excel template file
      // if this is a RTT, we need to put the date into the rtt start and end column
      // if this is a "CP" we need to put the date into the CP start and end column

      // im just creating differents key on the object, so the template will not display if undefined.
      rowsManipulated.forEach((employe) => {
        const {choixDebutCp, choixFinCp} = this.AnalyzeByDayAndReturnCategory(employe, 'Congé payé')
        const {choixDebutRtt, choixFinRtt} = this.AnalyzeByDayAndReturnCategory(employe, 'RTT')
        const {choixDebutSansSolde, choixFinSansSolde} = this.AnalyzeByDayAndReturnCategory(employe, 'Congé sans solde')
          if(employe.Statut === 'Wrong'){
            return 
          } 
          else {
            switch(true) {
              case employe.Type.includes('RTT'): 
                this.setState({
                  items : {employees : [
                    ...this.state.items.employees,
                      { 
                        ...employe,
                        debutRtt : employe.premierJour,
                        choixDebutRtt,
                        choixFinRtt,
                        finRtt: employe.dernierJour,
                      }
                  ]}
                })
                break;
              case employe.Type.includes('Congé sans solde'):
                this.setState({
                  items : { employees : [
                    ...this.state.items.employees,
                      { 
                        ...employe,
                        debutSansSolde: employe.premierJour,
                        choixDebutSansSolde,
                        choixFinSansSolde,
                        finSansSolde: employe.dernierJour,
                      }
                  ]}
                  })
                break
              case employe.Type.includes('Congé payé') :
                this.setState({
                  items : { employees : [
                    ...this.state.items.employees,
                      { 
                        ...employe,
                        debutCp: employe.premierJour,
                        choixDebutCp:choixDebutCp,
                        choixFinCp:choixFinCp,
                        finCp: employe.dernierJour,
                      }
                  ]}
                  })
                break
              default:
                const firstDay = new Date(Math.round((employe.Premierjour - 25569)*86400*1000));
                const lastDay = new Date(Math.round((employe.Dernierjour - 25569)*86400*1000));
                this.setState({
                  itemsToComplete : {
                    employees:
                    [
                    ...this.state.itemsToComplete.employees, 
                      {
                        ...employe,
                        stillToComplete: true,
                        debutCp: this.ExcelDateToJSDate(firstDay),
                        finCp: this.ExcelDateToJSDate(lastDay),
                      }
                    ]
                  }
                })
            }
          }
        })
      }
      this.setState({
        errorUploadingVsaFile: {
          ...this.state.errorUploadingVsaFile,
          isAnError:false,
        },
        isUploaded : [
          true,
          ...this.state.isUploaded.slice(1, this.state.isUploaded.length),
        ],
        availableFiles: [
          this.state.items.employees.length > 0 ? {
            name: `payfit_export_${this.state.month[this.state.d.getMonth()]}.xlsx`,
            description :  this.state.items.employees.filter((e) => e.payfitId).length > 1? 'lignes ont été traitées. Il suffit maintenant d\'importer le fichier sur Payfit' :'ligne a été traitée. Il suffit maintenant d\'importer le fichier sur Payfit' ,
            processedRows : this.state.items.employees.filter((e) => e.payfitId).length
          } : '',
          this.state.itemsToComplete.employees.length > 0 ? {
            name: `payfit_need_manual_processing_${this.state.month[this.state.d.getMonth()]}.xlsx`,
            description : this.state.itemsToComplete.employees.length > 1? 'lignes nécessitent un traitement manuel' : 'ligne nécessite un traitement manuel' ,
            processedRows : this.state.itemsToComplete.employees.length
          } : ''
      ]
      }) 
    });    
  }
  filePayfitHandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if(err){
        console.log(err);            
      }
      else {
        this.setState({ 
          errorUploadingPayfitFile: {
            ...this.state.errorUploadingPayfitFile,
            fileName: fileObj.name
          }
        })
        if(!this.checker(resp.rows[1], this.state.waitedPropertyForPayfit)) {
          this.setState({
            isUploaded : [
              ...this.state.isUploaded.slice(0,1),
              false,
            ],
            errorUploadingPayfitFile: {
              ...this.state.errorUploadingPayfitFile,
              isAnError: true,
              errorDesc: 'Le fichier importé ne correspond pas aux attentes. Merci d\'insérer un import Payfit.xlsx contenant les identifiants, les matricules, les analytiques'
            }
          })
          return
        }
        const newRows = resp.rows.filter((e) => e.length > 0)
        const rows = newRows.slice(2, newRows.length).map((row) => {
          const obj3 = {};
          // here, we create an object for each rows and associate column to value.
          row.forEach((element, index) => {
            if(obj3[[resp.rows[1][index].split(' ').join('').replace('\'', '')]]) {
              obj3[['fin' + resp.rows[1][index].split(' ').join('').replace('\'', '')]] = element;
            } else {
              obj3[[resp.rows[1][index].split(' ').join('').replace('\'', '')]] = element;
            }
          });
          return obj3
        })
        this.setState({
          employesWithPayfitIds : rows.map((e) => {
            return {
              identifiantPayfit: e['Identifiant(nepasmodifier)'],
              matricule:e['Matricule'],
              analytique: e['Analytiques'],
              employe: e['Employé']
            }
          }),
        })
        if(this.state.items.employees.length > 0) {
          this.setState({
            items: {
              employees : [...this.state.items.employees.map((e, i) => {
                const employeeee = this.state.employesWithPayfitIds.find((emp) => emp.matricule === e.Matricule)
                if(!employeeee) {
                  this.setState({
                    itemsToComplete: {
                      employees: [...this.state.itemsToComplete.employees, e]
                    }
                  })
                  return e
                }
                else {
                  return {
                    ...e,
                    payfitId: employeeee.identifiantPayfit,
                    analytique: employeeee.analytique,
                  }
                }

              })]
            },
          })
        }
        this.setState({
          items: {
            employees : [...this.state.items.employees.filter((e) => e.payfitId)]
          }
        })
        this.setState({
          availableFiles: [
            this.state.items.employees.length > 0 ? {
              name: `payfit_export_${this.state.month[this.state.d.getMonth()]}.xlsx`,
              description :  this.state.items.employees.filter((e) => e.payfitId).length > 1? 'lignes ont été traitées. Il suffit maintenant d\'importer le fichier sur Payfit' :'ligne a été traitée. Il suffit maintenant d\'importer le fichier sur Payfit' ,
              processedRows : this.state.items.employees.filter((e) => e.payfitId).length
            } : '',
            this.state.itemsToComplete.employees.length > 0 ? {
              name: `payfit_need_manual_processing_${this.state.month[this.state.d.getMonth()]}.xlsx`,
              description : this.state.itemsToComplete.employees.length > 1? 'lignes nécessitent un traitement manuel' : 'ligne nécessite un traitement manuel' ,
              processedRows : this.state.itemsToComplete.employees.length
            } : ''
        ],
        errorUploadingPayfitFile: {
          ...this.state.errorUploadingPayfitFile,
          isAnError:false,
        },
      isUploaded : [
        ...this.state.isUploaded.slice(0,1),
        true,
      ],
        }) 
      }
  })}
  async updateExcelFile(
    ) {
      if(this.state.items.employees.length > 0) {
        fetch(payfit)
        .then((response) => response.arrayBuffer())
        .then((buffer) => new Renderer().renderFromArrayBuffer(buffer, this.state.items))
        .then((report) => report.xlsx.writeBuffer())
        .then((buffer) => {
          saveAs(new Blob([buffer]), `payfit_export_${this.state.month[this.state.d.getMonth()]}.xlsx`)
        })
        .catch((err) => console.log("Error writing excel export", err))
      }
      if(this.state.itemsToComplete.employees.length > 0) {
        fetch(vsaProcess)
        .then((response) => response.arrayBuffer())
        .then((buffer) => new Renderer().renderFromArrayBuffer(buffer, this.state.itemsToComplete))
        .then((report) => report.xlsx.writeBuffer())
        .then((buffer) => {
          saveAs(new Blob([buffer]), `payfit_need_manual_processing_${this.state.month[this.state.d.getMonth()]}.xlsx`)
        })
        .catch((err) => console.log("Error writing excel export", err))
      }
    }
  render() {
    return (
          <div className="App">
            <div> <Header/> </div>
            <div id="confetti-wrapper">
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
        </div>
        <h5 style={{textAlign: 'center'}}> La page pour le traitement automatique des <strong> Absences </strong> </h5>
        <div className='flex'>
        <form id="form-file-upload" onSubmit={(e) => e.preventDefault()}>
          <input type="file"  onChange={this.fileHandler.bind(this)} id="input-file-upload" multiple={true} />
          <label id="label-file-upload" htmlFor="input-file-upload">
          { this.state.errorUploadingVsaFile.isAnError ?  <FcHighPriority  className='top_right_absolute'/>  : '' }
          { this.state.isUploaded[0] ?  <FcOk  className='top_right_absolute'/>  : '' }
            <div className="flex flex-column">
            <div> Importer un fichier VSA </div>
            <div style={{textDecoration: 'underline', fontSize:'0.7em'}}>{this.state.errorUploadingVsaFile.fileName} </div>
            <span> { this.state.errorUploadingVsaFile.isAnError ?  <div style={{color: "red"}}>{this.state.errorUploadingVsaFile.errorDesc} </div>  : '' }
            </span>
            </div> 
          </label>
        </form>
        <form  id="form-file-upload_payfit" onSubmit={(e) => e.preventDefault()}>
          <input type="file"  onChange={this.filePayfitHandler.bind(this)} id="input-file-upload_payfit" multiple={true} />
          <label id='label-file-upload_payfit' htmlFor="input-file-upload_payfit">
            { this.state.errorUploadingPayfitFile.isAnError ?  <FcHighPriority  className='top_right_absolute'/>  : '' }
            { this.state.isUploaded[1] ?  <FcOk  className='top_right_absolute'/>  : '' }
            <div className="flex flex-column">
            <div> Importer un fichier Payfit </div>
            <div style={{textDecoration: 'underline', fontSize:'0.7em'}}> {this.state.errorUploadingPayfitFile.fileName}</div>
            { this.state.errorUploadingPayfitFile.isAnError ?  <div style={{color: "red"}}> {this.state.errorUploadingPayfitFile.errorDesc} </div>  : '' }
            </div> 
          </label>
        </form>
        </div>
        <div> 
          {
           !this.state.isUploaded.includes(false)? 
          <div className='text-center' style={{color: '#efa666'}}> 
          <strong> Ces fichiers sont disponibles au téléchargement : </strong> 
          {this.state.availableFiles.map((e) => {
            if(e === '') return <> </>
              return <div key={e.name}> {e.name} : {e.processedRows} {e.description} </div> 
            })} 
          <div id="wrap" onClick={this.updateExcelFile.bind(this)}>
            <div class="btn-slide2">
              <span class="circle2" > <FiDownload/> </span>
              <span class="title2" > Télécharger </span>
              <span class="title-hover2" > Cliquez ici </span>
            </div>
          </div>
        </div>
        : ''
        }
          </div>

          <div className='credits'> 
          Developpé par <a href="https://www.linkedin.com/in/davidbasset/"> David Basset </a>
          </div>
      </div>

    );

  }
}

export default App;
